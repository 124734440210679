import axios from "axios";
import { Session } from "next-auth";
import { SessionProvider, signIn } from "next-auth/react";
import { DefaultSeo } from "next-seo";
import { AppProps } from "next/app";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { SWRConfig } from "swr";
import Layout from "../components/Layout";
import { isProduction } from "../helpers/environment";
import "../styles/main.scss";

const tagManagerArgs = {
  gtmId: "GTM-5LJBZ8X",
};

type OwnAppProps = AppProps & {
  session?: Session | null;
};

function MyApp(props: OwnAppProps) {
  const { Component, pageProps, session } = props;
  const { seo } = pageProps;

  useEffect(() => {
    if (isProduction) {
      TagManager.initialize(tagManagerArgs);
    }
  }, []);

  useEffect(() => {
    if (pageProps.redirectTo) {
      signIn("auth0", {
        callbackUrl: pageProps.redirectTo,
      });
    }
  }, []);

  if (pageProps.redirectTo) {
    return null;
  }

  return (
    <SessionProvider session={session}>
      <SWRConfig
        value={{
          fetcher: (url) =>
            axios
              .get(url)
              .then((res) => res.data)
              .catch((error) => {
                if (error?.response) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  throw new Error(error?.response.data);
                } else if (error?.request) {
                  // The request was made but no response was received
                  // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                  // http.ClientRequest in node.js
                  throw new Error(error?.request);
                } else {
                  // Something happened in setting up the request that triggered an Error
                  throw new Error(error?.message);
                }
              }),
        }}
      >
        <Layout pageProps={pageProps}>
          <DefaultSeo
            title={seo?.seo?.title || pageProps?.story?.name || ""}
            titleTemplate={`%s ${
              seo?.defaultSeo?.og_title ? `| ${seo?.defaultSeo?.og_title}` : ""
            }`}
            description={
              seo?.seo?.description ||
              pageProps?.story?.content?.subtitle ||
              seo?.defaultSeo?.og_description ||
              ""
            }
            canonical={`${process.env.NEXT_PUBLIC_SITE_URL}${pageProps?.story?.full_slug}/`}
            openGraph={{
              type: "website",
              locale: "en_UK",
              url: `${process.env.NEXT_PUBLIC_SITE_URL}${pageProps?.story?.full_slug}/`,
              title: `${seo?.seo?.title || pageProps?.story?.name || ""} | ${
                seo?.defaultSeo?.og_title
              }`,
              description:
                seo?.seo?.description ||
                pageProps?.story?.content?.subtitle ||
                seo?.defaultSeo?.og_description ||
                "",
              images: [
                {
                  url: `${
                    pageProps?.story?.content?.hero_image?.filename ||
                    seo?.defaultSeo.og_image ||
                    pageProps?.story?.content?.article_image?.filename ||
                    ""
                  }/m/1200x630/smart/filters:format(jpeg)`,
                  width: 1200,
                  height: 630,
                  alt:
                    pageProps?.story?.content?.hero_image?.alt ??
                    pageProps?.story?.content?.article_image?.alt ??
                    "",
                  type: "image/jpeg",
                },
              ],
            }}
            additionalLinkTags={[
              {
                rel: "icon",
                href: "/favicon.ico",
              },
              {
                rel: "icon",
                type: "image/svg+xml",
                href: "/icon.svg",
              },
              {
                rel: "apple-touch-icon",
                href: "/apple-touch-icon.png",
              },
            ]}
          />
          <Component {...pageProps} />
        </Layout>
      </SWRConfig>
    </SessionProvider>
  );
}

export default MyApp;
